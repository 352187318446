.InputFile {
  margin-bottom: 24px;

  &__label {
    width: 100%;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    background-color: #FFFFFF;
    display: inline-block;

    &:hover {
      box-shadow: 0 4px 9px 0 rgba(73, 116, 146, 0.1);
    }
  }

  &__input {
    display: none;
    visibility: hidden;
  }
}
