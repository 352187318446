.InputFileLabelFilled {
  height: 48px;
  display: flex;
  align-items: center;
  text-align: center;

  &__text {
    flex-grow: 1;
    color: #595959;
    font-size: 16px;
    font-weight: 300;
    padding: 12px 0 12px 12px;
    letter-spacing: 0;
    line-height: 19px;
  }

  &__icon {
    padding: 10px;
    margin-top: 3px;
    margin-right: 6px;
  }

}
