@import "../../asstes/styles/animations/pulseDot";
@import "../../asstes/styles/animations/pulseRing";

.MapMarkerAdd {
  width: 64px;
  height: 64px;
  z-index: 25;
  pointer-events: all !important;

  &__remove {
    position: absolute;
    margin-top: -10px;
    font-size: 18px;
    z-index: 30;
    font-weight: 700;
    margin-left: 90%;
  }

  &__close {
    width: fit-content;
  }

  &__circle {
    &--inner {
      top: 0;
      left: 0;
      display: flex;
      position: absolute;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 32px;
      background-color: #FFFFFF;
      box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.14);
      animation: pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;

      svg {
        margin: 0 auto;
        display: block;
      }
    }

    &--outer {
      width: 150%;
      height: 150%;
      margin-left: -25%;
      margin-top: -25%;
      border-radius: 48px;
      position: absolute;
      background-color: #497492;
      animation: pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }

    &--outer-2 {
      width: 200%;
      height: 200%;
      margin-left: -50%;
      margin-top: -50%;
      border-radius: 64px;
      position: absolute;
      background-color: #497492;
      animation: pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
  }
}
