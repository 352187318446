.Modal {
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.5);

  &--active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  &__content {
    width: inherit;
    height: inherit;
    display: flex;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  &__body {
    margin: auto;
    width: 340px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.14);
  }

  .Instructions3 {
    position: absolute;
    top: 15rem;
    right: 20rem;
    z-index: 15;
    display: flex;
  }

  .Instructions4 {
    position: absolute;
    top: 21rem;
    right: -18rem;
    z-index: 15;
    display: flex;
    flex-direction: row-reverse;
    font-weight: bold;

    .Instructions__Arrow {
      width: 10rem;
      display: flex;
      align-items: flex-end;
      transform: rotate(170deg) scale(1, -1);
    }
  }

  @media screen and (max-width: 930px) {
    .Instructions4, .Instructions3 {
      display: none;
    }
  }
}
