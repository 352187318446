* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: RobotoRegular, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'RobotoRegular';
  font-weight: 400;
  src: local('RobotoRegular'), url(../fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoBold';
  font-weight: 700;
  src: local('RobotoBold'), url(../fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}
