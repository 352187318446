.MapOverlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 95px;
  padding: 10px;
  z-index: 20;

  &__button {
    width: 29px;
    height: 29px;
    background-color: #FFFFFF;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .2);

    &:hover {
      background-color: #f6f6f6;
    }

    svg {
      width: 70%;
      height: 100%;
    }
  }
}

.mt-2 {
  margin-top: 0.5rem;
}
