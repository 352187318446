.MapMarkerFormAddSuccess {
  margin: 24px;

  &__button {
    width: 100%;
    border-radius: 4px;
    background-color: #497492;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    padding: 14px;
    text-transform: uppercase;
  }

  &__text {
    margin-bottom: 24px;
    width: 292px;
    color: #497492;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    max-width: 300px;

    &--bold {
      font-weight: bold;
    }
  }
}
