.Instructions {
  position: absolute;
  top: 1rem;
  right: 3rem;
  z-index: 15;
  display: flex;
  font-weight: bold;

  &__Bubble {
    max-width: 9.5rem;
    min-height: 9.5rem;
    padding: 1.25rem;
    background-color: #f78b44;
    color: #FFF;
    border-radius: 9.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: RobotoBold, sans-serif;
  }

  &__Close {
    margin-bottom: 5rem;
    cursor: pointer;
    color: #000000;
    font-weight: 700;
  }

  &__Arrow {
    width: 10rem;
    top: 8rem;
    display: flex;
    align-items: flex-end;
  }
}

.Instructions2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-12%, 60%);
  display: flex;
  flex-direction: row-reverse;
  z-index: 20;

  .Instructions__Arrow {
    width: 10rem;
    height: 3rem;
    top: 8rem;
    display: flex;
    align-items: flex-end;
    transform: rotate(230deg) scale(1, -1);
  }
}
