.MapMarkerPopup {
  color: #595959;
  padding: 8px;
  overflow: hidden;

  &__category {
    width: 300px;
    color: #f78b44;
    text-align: center;
    padding: 0 0 0.5rem;
    font-size: 1.125rem;
    text-transform: capitalize;
  }

  &__title {
    color: #1a544d;
    text-align: center;
    padding-bottom: 6px;
  }

  &__image {
    padding: 6px;
    max-height: 200px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__wrapper {
    transform: none !important;
    top: 3rem !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto;

    .mapboxgl-popup-close-button {
      width: 25px;
      height: 25px;
      font-size: 20px;
      border-radius: 1rem;
      margin: 0.5rem;
      background-color: #1a544d;
      color: #19a03b;
    }
  }

  &__regular {
    padding-bottom: 0.5rem;
    font-size: 1rem;
    white-space: pre-line;
    color: #1a544d;
  }

  &__source {
    display: flex;
    justify-content: space-between;

    a {
      color: #595959 !important;
    }

    a:visited {
      color: #595959 !important;
    }
  }

  &__path {
    font-size: 18px;
    color: #1a544d;
    text-align: center;
    padding: 6px;
  }
}
