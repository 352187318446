.MapPathsMenu {
  background: #fff;
  margin-top: 10px;
  border-radius: 3px;
  width: 150px;
  max-height: 600px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  overflow: auto;

  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* Internet Explorer 11 */
  &::-webkit-scrollbar { /** WebKit */
    display: none;
  }

  a {
    font-size: 13px;
    color: #ffffff;
    background-color: #668985;
    display: flex;
    margin: 0;
    padding: 7px;
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    text-align: center;

    .checked {
      display: none;
    }
  }

  a:last-child {
    border: none;
  }

  a:hover {
    background-color: #557270;
    color: #404040;
  }

  a.active {
    background-color: #668985;
    color: #ffffff;

    .checked {
      display: block;
    }

    .unchecked {
      display: none;
    }
  }

  a.active:hover {
    background: #557270;
  }

  &__text {
    width: 110px;
    color: #ffffff;
  }

  svg {
    height: 28px;
    width: 28px;
    margin: auto;
  }

  &.Map__categories__feedback {
    a:first-child {
      background-color: #EF7D2C;

      &:hover {
        background-color: #E47729;
      }
    }
  }

  .menu-option.Walk {
    .checked, .unchecked {
      polyline, rect {
        stroke: #000000 !important;
      }
    }
  }

  .menu-option.Bike {
    .checked, .unchecked {
      polyline, rect {
        stroke: #1A544D !important;
      }
    }
  }

  .menu-option.Mtb {
    .checked, .unchecked {
      polyline, rect {
        stroke: #EF7D2C !important;
      }
    }
  }

  .menu-option.Horse {
    .checked, .unchecked {
      polyline, rect {
        stroke: #cc3300 !important;
      }
    }
  }

  .menu-option.Kayak {
    .checked, .unchecked {
      polyline, rect {
        stroke: #B44403 !important;
      }
    }
  }

  .menu-option.Round {
    .checked, .unchecked {
      polyline, rect {
        stroke: #EF7D2C !important;
      }
    }
  }
}