.MapMarkerCluster {
  cursor: pointer;

  .MapMarker__circle {
    background-color: #B44403;
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: 700;
    border: 0.5px solid #fff;
  }
}