.Map {
  width: 100vw;
  height: 100vh;

  &__instructions {
    position: absolute;
    width: inherit;
    height: inherit;
    top: 0;

    &-inactive {
      display: none;
    }
  }

  &__container {
    width: inherit;
    height: inherit;
  }

  &__hidden {
    display: none;
  }

  &__logos {
    position: absolute;
    display: flex;
    bottom: 2rem;
    right: 1rem;
    z-index: 5;
  }

  &__menus {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 10px;
  }

  &__categories {
    background: #fff;
    z-index: 1;
    border-radius: 3px;
    width: 150px;
    max-height: 600px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    overflow: auto;

    scrollbar-width: none; /* Firefox 64 */
    -ms-overflow-style: none; /* Internet Explorer 11 */
    &::-webkit-scrollbar { /** WebKit */
      display: none;
    }

    a {
      font-size: 13px;
      color: #ffffff;
      background-color: #668985;
      display: flex;
      margin: 0;
      padding: 7px;
      text-decoration: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      text-align: center;

      .checked {
        display: none;
      }
    }

    a:last-child {
      border: none;
    }

    a:hover {
      background-color: #557270;
      color: #404040;
    }

    a.active {
      background-color: #668985;
      color: #ffffff;

      .checked {
        display: block;
      }

      .unchecked {
        display: none;
      }
    }

    a.active:hover {
      background: #557270;
    }

    a#Overnatning {
      background-color: #CE5F27;

      &:hover {
        background-color: #b1471a;
      }
    }

    a#Spisesteder {
      background-color: #7A8731;

      &:hover {
        background-color: #5b6724;
      }
    }

    a#Aktiviteter_og_Oplevelser {
      background-color: #FFBB5C;

      &:hover {
        background-color: #bb8a45;
      }
    }

    a#Salg_og_Indkøb {
      background-color: #48778F;

      &:hover {
        background-color: #3e657a;
      }
    }

    a#Faciliteter {
      background-color: #B44403;

      &:hover {
        background-color: #853103;
      }
    }

    &__text {
      width: 110px;
      color: #ffffff;
    }

    svg {
      height: 28px;
      width: 28px;
      margin: auto;
    }

    &.Map__categories__feedback {
      a:first-child {
        background-color: #EF7D2C;

        &:hover {
          background-color: #E47729;
        }
      }
    }
  }
}

.mapboxgl-popup {
  z-index: 5;

  &-content {
    width: 100%;
    height: 100%;
    max-height: 500px;
    overflow: auto;

    @media screen and (max-width: 600px) {
      width: 300px;
      height: 100%;
    }
  }

  &-close-button {
    width: 25px;
    height: 25px;
    font-size: 20px;
  }

  &-tip {
    display: none;
  }
}

.mx-2 {
  margin: 0 0.5rem;
}

.info-popup {
  position: absolute;
  transform: none !important;
  top: 1rem !important;
  left: 0 !important;
  right: 10rem !important;
  color: #668985;
  font-family: RobotoRegular, sans-serif;
  margin: auto;

  h1 {
    font-family: RobotoBold, sans-serif;
    font-size: 3rem;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 400;
  }

  p {
    text-align: justify;
    font-size: 11px;
    line-height: initial;
  }

  .mapboxgl-popup-content {
    padding: 30px 10px 15px;
    text-align: center;
  }
}
