.MapMarker {
  cursor: pointer;

  &__circle {
    display: flex;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 1000px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.14);

    svg {
      margin: 0 auto;
      display: block;
      height: 100%;
    }
  }

  &.Borgernes_Fortællinger {
    z-index: 1;

    svg {
      path {
        fill: #EF7D2C;
      }
    }
  }
}
