.InputText {
  margin-bottom: 24px;

  &__label {
    display: block;
    color: #595959;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 8px;
  }

  &__input {
    outline: none;
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 300;
    padding: 10px;
    background-color: #FFFFFF;

    &:hover {
      box-shadow: 0 4px 9px 0 rgba(73,116,146,0.1);
    }

    &:focus {
      border: 1px solid #497492;
      box-shadow: 0 4px 9px 0 rgba(73,116,146,0.1);
    }

    .InputText--error & {
      border: 1px solid #F64F64;
    }
  }

  &__error {
    height: 19px;
    width: 100%;
    color: #F64F64;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 19px;
    text-align: left;
    display: inline-block;
  }
}
