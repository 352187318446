.InputFileLabelEmpty {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    color: #595959;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  }

  &__icon {
    padding-top: 2px;
    padding-right: 8px;
  }
}
