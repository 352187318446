.MapMarkerForm {

  &__form {
    margin: 24px;
  }

  &__button {
    outline: none;
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    width: 100%;
    border-radius: 4px;
    background-color: #497492;
    padding: 14px;

    &:hover {
      box-shadow: 0 4px 9px 0 rgba(73, 116, 146, 0.5);
    }


    &:active {
      background-color: #305873;
    }

    &--cancel {
      margin-top: 24px;
      color: #497492;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 19px;
      width: 100%;
      border: none;
      background: none;
      outline: none;
      text-transform: uppercase;
    }
  }

  &__header {
    height: 32px;

    &--circle {
      display: flex;
      align-items: center;
      height: 64px;
      width: 64px;
      margin: 0 auto;
      bottom: 32px;
      border-radius: 32px;
      position: relative;
      background-color: #FFFFFF;
      box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.14);

      svg {
        margin: 0 auto;
        display: block;
      }
    }
  }
}
